<script setup lang="ts">
import type { PaAPI } from '@rialtic/types'

type Region = PaAPI.Get['/region'][number]

const props = defineProps<{
  modelValue: boolean
  value?: string
  valueRegions?: PaAPI.Get['/reference-document/{id}']['reference_document_region']
  levelOneId?: string
  isTitleDisabled?: boolean
}>()

const emit = defineEmits(['save', 'save-region', 'update:modelValue'])

const apiClient = useApiClient()

const { data } = await useLazyAsyncData<PaAPI.Get['/source-type-level-two']>(
  '/source-type-level-two',
  () => apiClient.fetch('/source-type-level-two'),
  {
    transform: (data) =>
      data.toSorted((a, b) => {
        return a.name.localeCompare(b.name)
      }),
  },
)
const { data: regionData } = await useLazyAsyncData<PaAPI.Get['/region']>(
  '/region',
  () => apiClient.fetch('/region'),
)

const sortBy = [
  {
    prop: 'region_type',
    direction: 1,
  },
  {
    prop: 'region',
    direction: 1,
  },
]
const regionList = computed(() => {
  if (!regionData.value?.length) return []

  return regionData.value.toSorted((a, b) => {
    let i = 0,
      result = 0
    while (i < sortBy.length && result === 0) {
      result =
        sortBy[i].direction *
        (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString()
          ? -1
          : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString()
          ? 1
          : 0)
      i++
    }
    return result
  })
})

const open = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const search = ref('')

const optionsForLevel1 = computed(
  () =>
    data.value?.filter(
      (item) => item.source_type_level_1_id === props.levelOneId,
    ) || [],
)
const list = useSimpleSearch(
  computed(() => optionsForLevel1.value),
  search,
  'name',
)

const activeId = ref(props.value || '')
const activeItem = computed(
  () => data.value?.find((doc) => doc.id === activeId.value),
)
const currentItem = computed(
  () => data.value?.find((doc) => doc.id === props.value),
)

const buildActiveRegions = () =>
  props.valueRegions?.reduce((state, { region, region_id }) => {
    state[region_id] =
      region || regionData.value.find(({ id }) => id === region_id)
    return state
  }, {})
const activeRegions = ref(buildActiveRegions() as Record<Region['id'], Region>)

const isRegionPicker = computed(
  () =>
    optionsForLevel1.value.length &&
    optionsForLevel1.value[0].name === 'SOURCE_TYPE_REGION',
)

const onSave = () => {
  if (isRegionPicker.value) {
    emit('save-region', Object.values(activeRegions.value))
  } else {
    emit('save', activeId.value)
  }

  open.value = false
}

const toggleRegion = (region: Region) => {
  if (activeRegions.value[region.id]) {
    const updatedRegions = { ...activeRegions.value }
    delete updatedRegions[region.id]
    activeRegions.value = updatedRegions
  } else {
    activeRegions.value = {
      ...activeRegions.value,
      [region.id]: region,
    }
  }
}

watch(
  () => props.modelValue,
  async (isOpen) => {
    if (!isOpen) return

    activeId.value = props.value || ''
    activeRegions.value = buildActiveRegions()
  },
)
const isMounted = useMounted()
</script>

<template>
  <div>
    <div v-if="!levelOneId" class="flex items-center justify-between px-4 py-2">
      <div class="flex-1">
        <h3 v-if="!isTitleDisabled" class="subtitle-2 text-neutral-500">
          Source type level 2
        </h3>
        <p class="truncate italic text-neutral-600">
          Source level one required
        </p>
      </div>
    </div>
    <div
      v-else-if="!optionsForLevel1.length"
      class="flex items-center justify-between px-4 py-2"
    >
      <div class="flex-1">
        <h3 v-if="!isTitleDisabled" class="subtitle-2 text-neutral-500">
          Source type level 2
        </h3>
        <p class="truncate italic text-neutral-600">
          No source level two options
        </p>
      </div>
    </div>
    <label
      v-else-if="isRegionPicker"
      class="flex cursor-pointer items-center justify-between px-4 py-2"
    >
      <div class="flex-1">
        <h3 v-if="!isTitleDisabled" class="subtitle-2 text-neutral-500">
          Source type level 2 - Region
        </h3>
        <p
          v-if="!valueRegions?.length"
          class="text-neutral-600' truncate italic"
        >
          Choose regions
        </p>
        <div v-else class="flex space-x-2 overflow-y-auto">
          <div
            v-for="(region, region_id) in activeRegions"
            :key="region_id"
            class="flex items-center justify-center space-x-0.5 rounded-full border border-neutral-300 bg-white px-2 py-1"
          >
            <span class="text-center text-xs leading-none text-neutral-500">
              {{ region?.region || '' }}
            </span>
          </div>
        </div>
      </div>

      <div>
        <button class="btn-icon" type="button" @click="open = true">
          <div class="i-heroicons-pencil-solid" />
        </button>
      </div>
    </label>
    <label
      v-else
      class="flex cursor-pointer items-center justify-between px-4 py-2"
    >
      <div class="flex-1">
        <h3 v-if="!isTitleDisabled" class="subtitle-2 text-neutral-500">
          Source type level 2
        </h3>
        <p
          class="truncate"
          :class="{
            'italic text-neutral-600': !currentItem?.name,
          }"
        >
          {{ currentItem?.name || 'Choose source type level 2' }}
        </p>
      </div>

      <div>
        <button class="btn-icon" type="button" @click="open = true">
          <div class="i-heroicons-pencil-solid" />
        </button>
      </div>
    </label>
    <LazyUiDialog
      v-if="isMounted"
      :key="isRegionPicker ? 'region' : 'source_type_level_2'"
      v-model="open"
      max-width="680px"
    >
      <div class="flex h-full flex-col">
        <header class="border-b border-neutral-200 px-4 py-2">
          <div class="mb-2 flex w-full justify-between font-semibold">
            {{ isRegionPicker ? 'Region' : 'Source type level 2' }}

            <button
              class="rounded-full p-1"
              type="button"
              @click="open = false"
            >
              <div class="i-ph-x-bold" />
            </button>
          </div>

          <div
            v-if="!isRegionPicker"
            class="flex h-9 items-center justify-between"
          >
            <div
              class="flex h-8 items-center justify-center space-x-1 rounded-full border border-neutral-300 bg-white px-3 py-2"
            >
              <p class="text-center text-xs leading-none text-neutral-500">
                Source type level 2
              </p>
              <p
                v-if="activeId"
                class="max-w-60 truncate text-left text-xs font-medium leading-none text-neutral-800"
              >
                {{ activeItem?.name || '' }}
              </p>
              <p v-else class="text-xs italic text-neutral-600">
                Choose source type
              </p>
            </div>

            <div>
              <UiSearch
                v-model="search"
                placeholder="Search..."
                type="search"
              />
            </div>
          </div>
        </header>

        <div class="flex-1 overflow-y-auto">
          <ul class="flex flex-col space-y-1 p-1">
            <template v-if="isRegionPicker">
              <li v-for="region in regionList" :key="region.id">
                <!-- <input type="checkbox" :aria-label="`Toggle ${item.name}`" class="mr-2 pointer-events-none" :checked="!!activeEditTypes[item.id]"> {{ item.name }} -->
                <UiListItemCheckbox
                  :checked="!!activeRegions[region.id]"
                  @click="toggleRegion(region)"
                >
                  <div class="flex w-full items-center justify-between">
                    <div>{{ region.region }}</div>
                    <div>{{ region.region_type }}</div>
                  </div>
                </UiListItemCheckbox>
              </li>
            </template>
            <template v-else>
              <li v-for="{ id, name } in list" :key="id">
                <UiListItemTick
                  :checked="id === activeId"
                  @click="activeId = id"
                >
                  {{ name }}
                </UiListItemTick>
              </li>
            </template>
          </ul>
        </div>
        <footer
          class="flex items-center justify-end space-x-2 border-t border-neutral-200 px-4 py-2"
        >
          <UiButton type="button" variant="text" @click="open = false">
            Cancel
          </UiButton>

          <UiButton type="button" @click="onSave()">Save</UiButton>
        </footer>
      </div>
    </LazyUiDialog>
  </div>
</template>

<script setup lang="ts">
import type { PaAPI } from '@rialtic/types'

const props = defineProps<{
  modelValue: boolean
  value?: string
  isTitleDisabled?: boolean
}>()

const emit = defineEmits(['save', 'update:modelValue'])

const apiClient = useApiClient()
const { data, refresh } = await useAsyncData<
  PaAPI.Get['/source-type-level-one']
>('/source-type-level-one', () => apiClient.fetch('/source-type-level-one'))

const open = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const search = ref('')
const list = useSimpleSearch(
  computed(() => data.value || []),
  search,
  'name',
)

const activeId = ref(props.value || '')

const activeItem = computed(
  () => data.value?.find((doc) => doc.id === activeId.value),
)
const currentItem = computed(
  () => data.value?.find((doc) => doc.id === props.value),
)

const onSave = () => {
  emit('save', activeId.value)
  open.value = false
}

watch(
  () => props.modelValue,
  (isOpen) => {
    if (!isOpen) return

    if (!data.value) {
      refresh()
    }
    activeId.value = props.value || ''
  },
)
const isMounted = useMounted()
</script>

<template>
  <label class="flex cursor-pointer items-center justify-between px-4 py-2">
    <div class="flex-1">
      <h3 v-if="!isTitleDisabled" class="subtitle-2 text-neutral-500">
        Source type level 1
      </h3>
      <p
        class="truncate"
        :class="{
          'italic text-neutral-600': !currentItem?.name,
        }"
      >
        {{ currentItem?.name || 'Choose source type' }}
      </p>
    </div>

    <div>
      <button class="btn-icon" type="button" @click="open = true">
        <div class="i-heroicons-pencil-solid" />
      </button>
    </div>
  </label>
  <LazyUiDialog v-if="isMounted" v-model="open" max-width="680px">
    <div class="flex h-full flex-col">
      <header class="border-b border-neutral-200 px-4 py-2">
        <div class="mb-2 flex w-full justify-between font-semibold">
          Source type level 1

          <button class="rounded-full p-1" type="button" @click="open = false">
            <div class="i-ph-x-bold" />
          </button>
        </div>

        <div class="flex h-9 items-center justify-between">
          <div
            class="flex h-8 items-center justify-center space-x-1 rounded-full border border-neutral-300 bg-white px-3 py-2"
          >
            <p class="text-center text-xs leading-none text-neutral-500">
              Source type level 1
            </p>
            <p
              v-if="activeId"
              class="max-w-60 truncate text-left text-xs font-medium leading-none text-neutral-800"
            >
              {{ activeItem?.name || '' }}
            </p>
            <p v-else class="text-xs italic text-neutral-600">
              Choose source type
            </p>
          </div>

          <div>
            <UiSearch v-model="search" placeholder="Search..." type="search" />
          </div>
        </div>
      </header>

      <div class="flex-1 overflow-y-auto">
        <ul class="flex flex-col space-y-1 p-1">
          <li v-for="{ id, name } in list" :key="id">
            <UiListItemTick :checked="id === activeId" @click="activeId = id">
              {{ name }}
            </UiListItemTick>
          </li>
        </ul>
      </div>
      <footer
        class="flex items-center justify-end space-x-2 border-t border-neutral-200 px-4 py-2"
      >
        <UiButton type="button" variant="text" @click="open = false">
          Cancel
        </UiButton>

        <UiButton type="button" @click="onSave()">Save</UiButton>
      </footer>
    </div>
  </LazyUiDialog>
</template>
